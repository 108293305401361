.home {
  text-align: center;
  .logo {
    height: 10vmin;
    pointer-events: none;
    margin: 1rem;
  }
  .header {
    font-size: fontSize(0.8);
    background-color: #282c34;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  h2 {
    font-size: fontSize(0.6);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
