@function fontSize($size) {
  @return calc(10px + #{$size} * 2.5vmin);
}

$background-color: #282c34;

body {
  --background-color: #282c34;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #58a6ff;
  text-decoration: none;

  &:hover {
    color: #58a6ff;
    text-decoration: underline;
  }

  &:visited {
    color: #58a6ff;
  }
}

@import "./home.scss";
@import "./editor.scss";
